import React from 'react'
import parse from 'html-react-parser'
// import AdBlock from '../AdBlock'
// import PluralAffiliate from '../PluralAffiliate'
import UdemyAffiliate from '../UdemyAffiliate'
// import BuyMeCoffeeBanner from '../BuyMeCoffeeBanner'
import './style.scss'

const handleShortcodes = (node) => {
    if (node.type && node.type === 'tag') {
        const shortcode = node.children[0]?.data

        // if (shortcode === '[ad-block]') {
        //     return <AdBlock />
        // }

        if (shortcode === '[ad-block]') {
            return <></>
        }

        if (shortcode === '[affilate-udemy-jest]') {
            return <UdemyAffiliate type="jest" />
        }

        if (shortcode === '[affilate-udemy-react-indepth]') {
            return <UdemyAffiliate type="react-indepth" />
        }

        if (shortcode === '[affilate-next-13-intro]') {
            return <UdemyAffiliate type="next-13-intro" />
        }

        if (shortcode === '[affilate-general-upskill]') {
            return <UdemyAffiliate type="general-upskill" />
        }

        if (shortcode === '[support-block]') {
            // return <BuyMeCoffeeBanner />
            return null
        }
    }

    // If nothing, return original node
    return node
}

const WYSIWYG = ({ content }) => {
    const reactElements = parse(content || '', {
        replace: handleShortcodes,
        library: require('preact'),
    })

    return <div>{reactElements}</div>
}

export default WYSIWYG
