import React from 'react'
import { wrapper } from './styles.module.scss'

const AffiliateDisclaimer = () => (
    <div className={wrapper}>
        <p>
            This post contain affiliate links to{' '}
            <a
                href="https://click.linksynergy.com/deeplink?id=oOowKQglvpk&mid=47900&murl=https%3A%2F%2Fwww.udemy.com"
                target="_blank"
            >
                Udemy courses
            </a>
            , meaning when you click the links and make a purchase, I receive a
            small commission. I only recommend courses that I believe support
            the content, and it helps maintain the site.
        </p>
    </div>
)

export default AffiliateDisclaimer
