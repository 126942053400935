import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { getImage, getSrc } from 'gatsby-plugin-image'
import 'clipboard'
import 'prismjs/plugins/toolbar/prism-toolbar'
import Prism from 'prismjs'
import DateMeta from '../components/DateMeta'
import CategoryList from '../components/CategoryList'
import RelatedCards from '../components/RelatedCards'
import AdBlock from '../components/AdBlock'
import AffiliateDisclaimer from '../components/AffiliateDisclaimer'
import SEO from '../components/SEO/SEO'
import TagList from '../components/TagList'
import WYSIWYG from '../components/WYSIWYG'
import FeaturedImage from '../components/FeaturedImage'
import ArticleContainer from '../containers/ArticleContainer'
import Layout from '../containers/Layout'

const PostTemplate = (props) => {
    useEffect(() => {
        Prism.highlightAll()
    }, [])

    const {
        data: {
            relatedPosts,
            wpPost: {
                content,
                cleanTitle,
                modifiedForUser,
                modifiedForSchema,
                featuredImage,
                tags,
                categories,
            },
        },
    } = props

    const affilateCodes = [
        '[affilate-udemy-jest]',
        '[affilate-udemy-react-indepth]',
        '[affilate-next-13-intro]',
        '[affilate-general-upskill]',
    ]

    const image = getImage(featuredImage?.node?.thumbnail)
    const featuredAlt = featuredImage.node?.altText || ''
    const featuredTitle = featuredImage?.node?.title || ''

    const hasAffiliate =
        content.includes(affilateCodes[0]) ||
        content.includes(affilateCodes[1]) ||
        content.includes(affilateCodes[2]) ||
        content.includes(affilateCodes[3])

    return (
        <Layout newsletter={categories.nodes[0]?.postTax}>
            <ArticleContainer>
                <article>
                    <h1>{cleanTitle}</h1>

                    <DateMeta
                        modifiedForUser={modifiedForUser}
                        modifiedForSchema={modifiedForSchema}
                    />

                    <CategoryList cats={categories.nodes} />

                    <FeaturedImage
                        image={image}
                        title={featuredTitle || ''}
                        alt={featuredAlt || ''}
                    />

                    {hasAffiliate && <AffiliateDisclaimer />}

                    <AdBlock />

                    <WYSIWYG content={content} />

                    <TagList tags={tags.nodes} />
                </article>
            </ArticleContainer>
            <RelatedCards relatedPosts={relatedPosts.nodes} />
        </Layout>
    )
}

export const postQuery = graphql`
    query post($id: Int!, $related: [Int!]) {
        wpPost(databaseId: { eq: $id }) {
            ...PostContent
            modifiedForUser: modified(formatString: "D MMMM YYYY")
            modifiedForSchema: modified(formatString: "YYYY-MM-DD, HH:mm:ss")
        }
        relatedPosts: allWpPost(filter: { databaseId: { in: $related } }) {
            nodes {
                ...RelatedContent
            }
        }
    }
`

export default PostTemplate

export const Head = ({ data, location }) => {
    const {
        wpPost: { cleanTitle, cleanExcerpt, modified, featuredImage, seo },
    } = data

    const facebookImage = getSrc(featuredImage?.node?.facebook)
    const twitterImage = getSrc(featuredImage?.node?.twitter)

    return (
        <SEO
            postType="post"
            yoastTitle={seo.title}
            title={cleanTitle}
            description={cleanExcerpt}
            facebookPostImage={facebookImage}
            twitterPostImage={twitterImage}
            url={location.pathname}
            dateModified={modified}
        />
    )
}
