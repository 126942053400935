import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { imageStyle } from './style.module.scss'

const FeaturedImage = ({ image, title, alt }) => (
    <GatsbyImage
        alt={alt}
        className={imageStyle}
        image={image}
        loading="eager"
        title={title}
    />
)

export default FeaturedImage
