import React from 'react'
import Link from 'Components/Link'
import {
    action,
    wrap,
    breakline,
    link,
    intro,
    text,
    smallText,
    smallStyle,
} from './style.module.scss'

const UdemyAffiliate = ({ type }) => {
    if (type === 'jest') {
        return (
            <div className={wrap}>
                <Link
                    target="_blank"
                    className={link}
                    to="https://click.linksynergy.com/deeplink?id=oOowKQglvpk&mid=47900&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Freact-testing-library%2F"
                >
                    If you are new to Jest or wanting to improve your unit
                    testing I really recommend taking a look at this great
                    course by Bonnie Schulkin on{' '}
                    <span>
                        Testing React with Jest and React Testing Library
                    </span>
                    .
                </Link>
            </div>
        )
    }

    if (type === 'react-indepth') {
        return (
            <div className={wrap}>
                <Link
                    target="_blank"
                    className={link}
                    to="https://click.linksynergy.com/deeplink?id=oOowKQglvpk&mid=47900&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Freact-front-to-back-2022%2F"
                >
                    If you are new to React or want to become more comfortable
                    with it, I really recommend taking a look at this great
                    course by Brad Traversy on{' '}
                    <span>learning React front to back.</span>.
                </Link>
            </div>
        )
    }

    if (type === 'next-13-intro') {
        return (
            <div className={wrap}>
                <Link
                    target="_blank"
                    className={link}
                    to="https://click.linksynergy.com/deeplink?id=oOowKQglvpk&mid=47900&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fthe-nextjs-13-bootcamp-the-complete-developer-guide"
                >
                    If you are new to Next JS, I really recommend taking a look
                    at this great course - <span>Next.js 13 Bootcamp</span>.
                </Link>
            </div>
        )
    }

    if (type === 'general-upskill') {
        return (
            <div className={wrap}>
                <Link
                    target="_blank"
                    className={link}
                    to="https://click.linksynergy.com/deeplink?id=oOowKQglvpk&mid=47900&murl=https%3A%2F%2Fwww.udemy.com%2Fcourses%2Fsearch%2F%3Fsrc%3Dukw%26q%3Dfrontend"
                >
                    Wanting to be a better developer?
                    <br />
                    <br /> I recommend taking a look at what Udemy has to offer.
                    There are almost 2000 courses on just Frontend Development
                    alone!
                    <br />
                    <br />
                    <span>Grow your software development skills</span>
                </Link>
            </div>
        )
    }

    return null
}

export default UdemyAffiliate
